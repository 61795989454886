import { Dialog, Disclosure, Transition } from "@headlessui/react";
import {
  ChevronRightIcon,
  CreditCardIcon,
  DocumentReportIcon,
  DocumentTextIcon,
  KeyIcon,
  LocationMarkerIcon,
  MenuIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  SwitchHorizontalIcon,
  TruckIcon,
  UserCircleIcon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DefaultProfilePic from "../../assets/img/default-profile-pic.jpg";
import G99Favicon from "../../assets/img/g99-favicon.png";
import { useAuth } from "../../utils/auth/auth";
import { classNames } from "../../utils/helpers/misc";

export default function Sidebar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [v2Navigation, setV2Navigation] = useState<any[]>([]);
  const [v3Navigation, setV3Navigation] = useState<any[]>([]);

  useEffect(() => {
    let tempV2Navigation: any[] = [];
    let tempV3Navigation: any[] = [];

    if (!auth.loading && auth.user) {
      if (process.env.REACT_APP_PRODUCTION === "false") {
        if (auth.v2Token) {
          tempV2Navigation = [
            // {
            //   name: "Reports",
            //   href: "/v2/reports",
            //   icon: DocumentReportIcon,
            //   current: location.pathname.includes("/v2/reports"),
            // },
            {
              name: "Self Serve Audits",
              href: "/v2/self-serve-audits",
              icon: UserCircleIcon,
              current: location.pathname.includes("/v2/self-serve-audits"),
            },
            {
              name: "Vehicle Audits",
              href: "/v2/vehicle-audits",
              icon: TruckIcon,
              current: location.pathname.includes("/v2/vehicle-audits"),
            },
            {
              name: "Person Audits",
              href: "/v2/person-audits",
              icon: ShieldCheckIcon,
              current: location.pathname.includes("/v2/person-audits"),
            },
            {
              name: "ASIC Management",
              href: "#",
              icon: CreditCardIcon,
              current:
                location.pathname.includes("/v2/asic-management") ||
                location.pathname.includes("/v2/other-asics") ||
                location.pathname.includes("/v2/asic-import"),
              children: [
                {
                  name: "ASIC Management",
                  href: "/v2/asic-management",
                  icon: CreditCardIcon,
                  current: location.pathname.includes("/v2/asic-management"),
                },
                {
                  name: "Other ASICs",
                  href: "/v2/other-asics",
                  icon: CreditCardIcon,
                  current: location.pathname.includes("/v2/other-asics"),
                },
                {
                  name: "ASIC Import",
                  href: "/v2/asic-import",
                  icon: SwitchHorizontalIcon,
                  current: location.pathname.includes("/v2/asic-import"),
                },
              ],
            },
            {
              name: "Self Serve Management",
              href: "#",
              icon: UserCircleIcon,
              current: location.pathname.includes("/v2/self-serve-locations"),
              children: [
                {
                  name: "Self Serve Locations",
                  href: "/v2/self-serve-locations",
                  icon: LocationMarkerIcon,
                  current: location.pathname.includes("/v2/self-serve-locations"),
                },
              ],
            },
            {
              name: "AEV Management",
              href: "#",
              icon: LocationMarkerIcon,
              current: location.pathname.includes("/v2/aev-locations"),
              children: [
                {
                  name: "AEV Locations",
                  href: "/v2/aev-locations",
                  icon: LocationMarkerIcon,
                  current: location.pathname.includes("/v2/aev-locations"),
                },
              ],
            },
            {
              name: "User Management",
              href: "/v2/user-management",
              icon: UsersIcon,
              current: location.pathname.includes("/v2/user-management"),
            },
          ];
        } else {
          tempV2Navigation = [];
        }

        if (auth.v3Token) {
          if (
            (auth.user.hasOwnProperty("role") && (auth.user.role === 4 || auth.user.role === 3)) ||
            (auth.user.hasOwnProperty("roleName") &&
              (auth.user.roleName === "Airport Officer" ||
                auth.user.roleName === "Self Serve Admin"))
          ) {
            // User is a self serve admin or airport officer
            tempV3Navigation = [
              {
                name: "Self Serve Audits",
                href: "/v3/self-serve-audits",
                icon: UserCircleIcon,
                current: location.pathname.includes("/v3/self-serve-audits"),
              },
              {
                name: "Self Serve Management",
                href: "#",
                icon: UserCircleIcon,
                current: location.pathname.includes("/v3/self-serve-locations"),
                children: [
                  {
                    name: "Self Serve Locations",
                    href: "/v3/self-serve-locations",
                    icon: LocationMarkerIcon,
                    current: location.pathname.includes("/v3/self-serve-locations"),
                  },
                ],
              },
            ];
          } else {
            tempV3Navigation = [
              {
                name: "Self Serve Audits",
                href: "/v3/self-serve-audits",
                icon: UserCircleIcon,
                current: location.pathname.includes("/v3/self-serve-audits"),
              },
              {
                name: "Person Audits",
                href: "/v3/person-audits",
                icon: ShieldCheckIcon,
                current:
                  location.pathname.includes("/v3/person-audits") &&
                  !location.pathname.includes("checkins"),
              },
              {
                name: "ASIC Management",
                href: "#",
                icon: CreditCardIcon,
                current:
                  location.pathname.includes("/v3/asic-management") ||
                  location.pathname.includes("/v3/other-asics") ||
                  location.pathname.includes("/v3/asic-import"),
                children: [
                  {
                    name: "ASIC Management",
                    href: "/v3/asic-management",
                    icon: CreditCardIcon,
                    current: location.pathname.includes("/v3/asic-management"),
                  },
                  {
                    name: "ASIC Import",
                    href: "/v3/asic-import",
                    icon: SwitchHorizontalIcon,
                    current: location.pathname.includes("/v3/asic-import"),
                  },
                  {
                    name: "New ASIC Management",
                    href: "/v3/new-asic-management",
                    icon: CreditCardIcon,
                    current: location.pathname.includes("/v3/new-asic-management"),
                  },
                  {
                    name: "New ASIC Import",
                    href: "/v3/new-asic-import",
                    icon: SwitchHorizontalIcon,
                    current: location.pathname.includes("/v3/new-asic-import"),
                  },
                ],
              },
              {
                name: "Self Serve Management",
                href: "#",
                icon: UserCircleIcon,
                current: location.pathname.includes("/v3/self-serve-locations"),
                children: [
                  {
                    name: "Self Serve Locations",
                    href: "/v3/self-serve-locations",
                    icon: LocationMarkerIcon,
                    current: location.pathname.includes("/v3/self-serve-locations"),
                  },
                ],
              },
              {
                name: "AEV Management",
                href: "#",
                icon: UserCircleIcon,
                current:
                  location.pathname.includes("/v3/audit-policies") ||
                  location.pathname.includes("/v3/security-checks") ||
                  location.pathname.includes("/v3/security-tiers") ||
                  location.pathname.includes("/v3/aev-locations") ||
                  location.pathname.includes("/v3/operational-needs") ||
                  location.pathname.includes("/v3/person-audits/checkins"),
                children: [
                  {
                    name: "AEV Audit Policies",
                    href: "/v3/audit-policies",
                    icon: DocumentTextIcon,
                    current: location.pathname.includes("/v3/audit-policies"),
                  },
                  {
                    name: "AEV Locations",
                    href: "/v3/aev-locations",
                    icon: LocationMarkerIcon,
                    current: location.pathname.includes("/v3/aev-locations"),
                  },
                  {
                    name: "Person Audit Checkins",
                    href: "/v3/person-audits/checkins",
                    icon: ShieldCheckIcon,
                    current: location.pathname.includes("/v3/person-audits/checkins"),
                  },
                  {
                    name: "Security Checks",
                    href: "/v3/security-checks",
                    icon: QuestionMarkCircleIcon,
                    current: location.pathname.includes("/v3/security-checks"),
                  },
                  {
                    name: "Security Tiers",
                    href: "/v3/security-tiers",
                    icon: QuestionMarkCircleIcon,
                    current: location.pathname.includes("/v3/security-tiers"),
                  },
                  {
                    name: "Operational Needs",
                    href: "/v3/operational-needs",
                    icon: QuestionMarkCircleIcon,
                    current: location.pathname.includes("/v3/operational-needs"),
                  },
                ],
              },
              {
                name: "Notes",
                href: "/v3/notes",
                icon: DocumentTextIcon,
                current: location.pathname.includes("/v3/notes"),
              },
            ];
          }
        } else {
          tempV3Navigation = [];
        }

        if (auth.v3Token && auth.user && auth.user.role === 1) {
          tempV3Navigation.push({
            name: "Reports",
            href: "/v3/reports",
            icon: DocumentReportIcon,
            current: location.pathname.includes("/v3/reports"),
          });

          tempV3Navigation.push({
            name: "API Keys",
            href: "/v3/api-keys",
            icon: KeyIcon,
            current: location.pathname.includes("/v3/api-keys"),
          });

          tempV3Navigation.push({
            name: "User Management",
            href: "/v3/user-management",
            icon: UsersIcon,
            current: location.pathname.includes("/v3/user-management"),
          });
        }
      } else {
        if (auth.v2Token) {
          tempV2Navigation = [
            // {
            //   name: "Reports",
            //   href: "/v2/reports",
            //   icon: DocumentReportIcon,
            //   current: location.pathname.includes("/v2/reports"),
            // },
            {
              name: "Self Serve Audits",
              href: "/v2/self-serve-audits",
              icon: UserCircleIcon,
              current: location.pathname.includes("/v2/self-serve-audits"),
            },
            // {
            //   name: "Vehicle Audits",
            //   href: "/v2/vehicle-audits",
            //   icon: TruckIcon,
            //   current: location.pathname.includes("/v2/vehicle-audits"),
            // },
            // {
            //   name: "Person Audits",
            //   href: "/v2/person-audits",
            //   icon: ShieldCheckIcon,
            //   current: location.pathname.includes("/v2/person-audits"),
            // },
            // {
            //   name: "ASIC Management",
            //   href: "#",
            //   icon: CreditCardIcon,
            //   current:
            //     location.pathname.includes("/v2/asic-management") ||
            //     location.pathname.includes("/v2/other-asics") ||
            //     location.pathname.includes("/v2/asic-import"),
            //   children: [
            //     // {
            //     //   name: "ASIC Management",
            //     //   href: "/v2/asic-management",
            //     //   icon: CreditCardIcon,
            //     //   current: location.pathname.includes("/v2/asic-management"),
            //     // },
            //     // {
            //     //   name: "Other ASICs",
            //     //   href: "/v2/other-asics",
            //     //   icon: CreditCardIcon,
            //     //   current: location.pathname.includes("/v2/other-asics"),
            //     // },
            //     {
            //       name: "ASIC Import",
            //       href: "/v2/asic-import",
            //       icon: SwitchHorizontalIcon,
            //       current: location.pathname.includes("/v2/asic-import"),
            //     },
            //   ],
            // },
            {
              name: "ASIC Import",
              href: "/v2/asic-import",
              icon: SwitchHorizontalIcon,
              current: location.pathname.includes("/v2/asic-import"),
            },
            // {
            //   name: "Self Serve Management",
            //   href: "#",
            //   icon: UserCircleIcon,
            //   current: location.pathname.includes("/v2/self-serve-locations"),
            //   children: [
            //     {
            //       name: "Self Serve Locations",
            //       href: "/v2/self-serve-locations",
            //       icon: LocationMarkerIcon,
            //       current: location.pathname.includes(
            //         "/v2/self-serve-locations"
            //       ),
            //     },
            //   ],
            // },
            // {
            //   name: "AEV Management",
            //   href: "#",
            //   icon: LocationMarkerIcon,
            //   current: location.pathname.includes("/v2/aev-locations"),
            //   children: [
            //     {
            //       name: "AEV Locations",
            //       href: "/v2/aev-locations",
            //       icon: LocationMarkerIcon,
            //       current: location.pathname.includes("/v2/aev-locations"),
            //     },
            //   ],
            // },
            {
              name: "User Management",
              href: "/v2/user-management",
              icon: UsersIcon,
              current: location.pathname.includes("/v2/user-management"),
            },
          ];
        } else {
          tempV2Navigation = [];
        }

        if (auth.v3Token) {
          if (
            (auth.user.hasOwnProperty("role") && auth.user.role === 4) ||
            (auth.user.hasOwnProperty("roleName") && auth.user.roleName === "Self Serve Admin")
          ) {
            // User is a self serve admin
            tempV3Navigation = [
              {
                name: "Self Serve Audits",
                href: "/v3/self-serve-audits",
                icon: UserCircleIcon,
                current: location.pathname.includes("/v3/self-serve-audits"),
              },
            ];
          } else if (
            (auth.user.hasOwnProperty("role") && auth.user.role === 3) ||
            (auth.user.hasOwnProperty("roleName") && auth.user.roleName === "Airport Officer")
          ) {
            // User is an airport officer
            tempV3Navigation = [
              {
                name: "Self Serve Audits",
                href: "/v3/self-serve-audits",
                icon: UserCircleIcon,
                current: location.pathname.includes("/v3/self-serve-audits"),
              },
              {
                name: "Person Audits",
                href: "/v3/person-audits",
                icon: ShieldCheckIcon,
                current:
                  location.pathname.includes("/v3/person-audits") &&
                  !location.pathname.includes("checkins"),
              },
            ];
          } else {
            tempV3Navigation = [
              {
                name: "Self Serve Audits",
                href: "/v3/self-serve-audits",
                icon: UserCircleIcon,
                current: location.pathname.includes("/v3/self-serve-audits"),
              },
              {
                name: "Person Audits",
                href: "/v3/person-audits",
                icon: ShieldCheckIcon,
                current:
                  location.pathname.includes("/v3/person-audits") &&
                  !location.pathname.includes("checkins"),
              },
              {
                name: "ASIC Management",
                href: "#",
                icon: CreditCardIcon,
                current:
                  location.pathname.includes("/v3/asic-management") ||
                  location.pathname.includes("/v3/other-asics") ||
                  location.pathname.includes("/v3/asic-import"),
                children: [
                  {
                    name: "ASIC Management",
                    href: "/v3/asic-management",
                    icon: CreditCardIcon,
                    current: location.pathname.includes("/v3/asic-management"),
                  },
                  {
                    name: "ASIC Import",
                    href: "/v3/asic-import",
                    icon: SwitchHorizontalIcon,
                    current: location.pathname.includes("/v3/asic-import"),
                  },
                ],
              },
              {
                name: "Self Serve Management",
                href: "#",
                icon: UserCircleIcon,
                current: location.pathname.includes("/v3/self-serve-locations"),
                children: [
                  {
                    name: "Self Serve Locations",
                    href: "/v3/self-serve-locations",
                    icon: LocationMarkerIcon,
                    current: location.pathname.includes("/v3/self-serve-locations"),
                  },
                ],
              },
              {
                name: "AEV Management",
                href: "#",
                icon: UserCircleIcon,
                current:
                  location.pathname.includes("/v3/audit-policies") ||
                  location.pathname.includes("/v3/security-checks") ||
                  location.pathname.includes("/v3/security-tiers") ||
                  location.pathname.includes("/v3/operational-needs") ||
                  location.pathname.includes("/v3/aev-locations"),
                children: [
                  {
                    name: "AEV Audit Policies",
                    href: "/v3/audit-policies",
                    icon: DocumentTextIcon,
                    current: location.pathname.includes("/v3/audit-policies"),
                  },
                  {
                    name: "AEV Locations",
                    href: "/v3/aev-locations",
                    icon: LocationMarkerIcon,
                    current: location.pathname.includes("/v3/aev-locations"),
                  },
                  {
                    name: "Person Audit Checkins",
                    href: "/v3/person-audits/checkins",
                    icon: ShieldCheckIcon,
                    current: location.pathname.includes("/v3/person-audits/checkins"),
                  },
                  {
                    name: "Security Checks",
                    href: "/v3/security-checks",
                    icon: QuestionMarkCircleIcon,
                    current: location.pathname.includes("/v3/security-checks"),
                  },
                  {
                    name: "Security Tiers",
                    href: "/v3/security-tiers",
                    icon: QuestionMarkCircleIcon,
                    current: location.pathname.includes("/v3/security-tiers"),
                  },
                  {
                    name: "Operational Needs",
                    href: "/v3/operational-needs",
                    icon: QuestionMarkCircleIcon,
                    current: location.pathname.includes("/v3/operational-needs"),
                  },
                ],
              },
              {
                name: "Notes",
                href: "/v3/notes",
                icon: DocumentTextIcon,
                current: location.pathname.includes("/v3/notes"),
              },
            ];
          }
        } else {
          tempV3Navigation = [];
        }

        if (auth.v3Token && auth.user && auth.user.role === 1) {
          tempV3Navigation.push({
            name: "Reports",
            href: "/v3/reports",
            icon: DocumentReportIcon,
            current: location.pathname.includes("/v3/reports"),
          });

          tempV3Navigation.push({
            name: "API Keys",
            href: "/v3/api-keys",
            icon: KeyIcon,
            current: location.pathname.includes("/v3/api-keys"),
          });

          tempV3Navigation.push({
            name: "User Management",
            href: "/v3/user-management",
            icon: UsersIcon,
            current: location.pathname.includes("/v3/user-management"),
          });
        }
      }
    }

    setV2Navigation(tempV2Navigation);
    setV3Navigation(tempV3Navigation);
  }, [auth.loading]);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 py-4 overflow-y-auto">
                  <div
                    onClick={() => navigate("/v2/user-management")}
                    className="flex flex-row items-center flex-shrink-0 px-4 cursor-pointer opacity-80 hover:opacity-100"
                  >
                    <img className="w-auto h-8" src={G99Favicon} alt="Workflow" />
                    <span className="ml-2 text-xl font-bold text-white">AEV Portal</span>
                  </div>
                  <nav className="px-2 mt-5 space-y-1">
                    {v2Navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-700 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-300"
                              : "text-gray-400 group-hover:text-gray-300",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 p-4 bg-gray-700">
                  <div className="flex-shrink-0 block group">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block w-10 h-10 rounded-full"
                          src={DefaultProfilePic}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">
                          {auth.user.firstName && auth.user.lastName
                            ? auth.user.firstName + " " + auth.user.lastName
                            : auth.user.username}
                        </div>
                        <div
                          onClick={() => navigate("/signout")}
                          className="text-sm font-medium cursor-pointer text-amber-400 group-hover:text-amber-500 w-fit"
                        >
                          Sign out
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-1 min-h-0 bg-gray-900">
          <div className="flex flex-col flex-1 py-4 overflow-y-auto scrollbar scrollbar-thin scrollbar-rounded-md scrollbar-thumb-gray-700 scrollbar-track-gray-500">
            <div
              onClick={() => navigate("/v2/user-management")}
              className="flex flex-row items-center flex-shrink-0 px-4 cursor-pointer opacity-80 hover:opacity-100"
            >
              <img className="w-auto h-8" src={G99Favicon} alt="Workflow" />
              <span className="ml-2 text-xl font-bold text-white">AEV Portal</span>
            </div>

            <nav className="flex-1 px-4 mt-5 space-y-2">
              <ul role="list" className="flex flex-col flex-1 gap-y-7">
                {v2Navigation.length > 0 && (
                  <li>
                    <div className="mb-1 text-xs font-semibold leading-6 text-gray-400">V2</div>
                    <ul role="list" className="-mx-2 space-y-1">
                      {v2Navigation.map((item) => (
                        <li key={item.name}>
                          {!item.children ? (
                            <Link
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-700 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-gray-300"
                                    : "text-gray-400 group-hover:text-gray-300",
                                  "mr-3 flex-shrink-0 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          ) : (
                            <Disclosure defaultOpen={item.current} as="div">
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={classNames(
                                      "text-gray-300 hover:bg-gray-700 hover:text-white",
                                      "w-full group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? "text-gray-300"
                                          : "text-gray-400 group-hover:text-gray-300",
                                        "mr-3 flex-shrink-0 h-6 w-6"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                    <ChevronRightIcon
                                      className={classNames(
                                        open ? "rotate-90 text-gray-400" : "text-gray-400",
                                        "ml-auto h-5 w-5 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </Disclosure.Button>
                                  <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                  >
                                    <Disclosure.Panel as="ul" className="px-2 mt-1">
                                      {item.children.map((subItem: any) => (
                                        <li key={subItem.name}>
                                          {/* 44px */}
                                          <Link to={subItem.href}>
                                            <Disclosure.Button
                                              as="div"
                                              className={classNames(
                                                subItem.current
                                                  ? "bg-gray-700 text-white"
                                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                "block rounded-md py-2 pr-2 pl-9 text-sm leading-6"
                                              )}
                                            >
                                              {subItem.name}
                                            </Disclosure.Button>
                                          </Link>
                                        </li>
                                      ))}
                                    </Disclosure.Panel>
                                  </Transition>
                                </>
                              )}
                            </Disclosure>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
                {v3Navigation.length > 0 && (
                  <li>
                    <div className="flex flex-row items-center mb-1 text-xs font-semibold leading-6 text-gray-400">
                      V3{" "}
                      <span className="ml-1.5 inline-flex items-center px-1.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        NEW
                      </span>
                    </div>
                    <ul role="list" className="-mx-2 space-y-1">
                      {v3Navigation.map((item) => (
                        <li key={item.name}>
                          {!item.children ? (
                            <Link
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-700 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-gray-300"
                                    : "text-gray-400 group-hover:text-gray-300",
                                  "mr-3 flex-shrink-0 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          ) : (
                            <Disclosure defaultOpen={item.current} as="div">
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={classNames(
                                      "text-gray-300 hover:bg-gray-700 hover:text-white",
                                      "w-full group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? "text-gray-300"
                                          : "text-gray-400 group-hover:text-gray-300",
                                        "mr-3 flex-shrink-0 h-6 w-6"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                    <ChevronRightIcon
                                      className={classNames(
                                        open ? "rotate-90 text-gray-400" : "text-gray-400",
                                        "ml-auto h-5 w-5 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </Disclosure.Button>
                                  <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                  >
                                    <Disclosure.Panel as="ul" className="px-2 mt-1">
                                      {item.children.map((subItem: any) => (
                                        <li key={subItem.name}>
                                          {/* 44px */}
                                          <Link to={subItem.href}>
                                            <Disclosure.Button
                                              as="div"
                                              className={classNames(
                                                subItem.current
                                                  ? "bg-gray-700 text-white"
                                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                "block rounded-md py-2 pr-2 pl-9 text-sm leading-6"
                                              )}
                                            >
                                              {subItem.name}
                                            </Disclosure.Button>
                                          </Link>
                                        </li>
                                      ))}
                                    </Disclosure.Panel>
                                  </Transition>
                                </>
                              )}
                            </Disclosure>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div className="flex flex-shrink-0 p-4 bg-gray-800">
            <div className="flex-shrink-0 block w-full group">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block rounded-full h-9 w-9"
                    src={DefaultProfilePic}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm font-medium text-white">
                    <span>
                      {auth.user.firstName && auth.user.lastName
                        ? auth.user.firstName + " " + auth.user.lastName
                        : auth.user.username}
                    </span>
                    {(auth.v2Token || auth.v3Token) && (
                      <span className="ml-1.5 inline-flex items-center px-1.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                        {auth.v2Token ? "V2/V3" : auth.v3Token ? "V3" : ""}
                      </span>
                    )}
                  </div>
                  <div
                    onClick={() => navigate("/signout")}
                    className="text-xs font-medium cursor-pointer text-amber-400 group-hover:text-amber-500 w-fit"
                  >
                    Sign out
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 md:pl-64">
        <div className="sticky top-0 z-10 pt-1 pl-1 bg-gray-100 md:hidden sm:pl-3 sm:pt-3">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  );
}
