import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import {
  AsicSearchParams,
  CreateAirportRequest,
  CreateAuditPolicyRequest,
  CreateLocationAuditConfigurationRequest,
  CreateLocationRequest,
  CreateReportConfigurationRequest,
  CreateSecurityCheckRequest,
  CreateSecurityTierRequest,
  CreateUserRequest,
  PersonSecuritySubmissionSearchParams,
  SelfServeAuditSearchParams,
  UpdateAirportRequest,
  UpdateAuditPolicyRequest,
  UpdateLocationRequest,
  UpdateReportConfigurationRequest,
  UpdateSecurityCheckRequest,
  UpdateSecurityTierRequest,
  UpdateUserRequest,
} from "../types";

class ApiV2 {
  token: string | null;
  tokenExpiry: Date | null;
  client: AxiosInstance;
  apiBaseUrl: string;

  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_API_V2_BASE_URL ?? "";
    this.token = localStorage.getItem("v2Token");
    this.tokenExpiry = localStorage.getItem("v2TokenExpiry")
      ? new Date(localStorage.getItem("v2TokenExpiry")!)
      : null;

    const headers: AxiosRequestHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.request.use((request) => {
      if (!this.tokenExpiry || (this.tokenExpiry && new Date() > this.tokenExpiry)) {
        if (!window.location.href.includes("/signin")) {
          window.location.href = "/signout";
        }
      }
      return request;
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response?.status)) {
          if (!window.location.href.includes("/signin")) {
            window.location.href = "/signout";
          }
        }
        return Promise.reject(error);
      }
    );
  }

  updateClient = (newToken: string, expiry: Date | null) => {
    this.token = newToken;
    if (expiry) this.tokenExpiry = expiry;

    // Update API
    const headers: AxiosRequestHeaders = {
      Accept: "application/json",
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.request.use((request) => {
      if (!this.tokenExpiry || (this.tokenExpiry && new Date() > this.tokenExpiry)) {
        if (!window.location.href.includes("/signin")) {
          window.location.href = "/signout";
        }
      }
      return request;
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response?.status)) {
          window.location.href = "/signout";
        }
        return Promise.reject(error);
      }
    );
    return this.client;
  };

  // Required endpoints
  // Users
  getMyAdminUserInfo = () => {
    return this.client.get(`/admin/users/me`);
  };

  getAdminUserById = (id: string) => {
    return this.client.get(`/admin/users/admins/${id}`);
  };

  getAirportUserById = (id: string) => {
    return this.client.get(`/admin/users/airport/${id}`);
  };

  getAdminUsers = () => {
    return this.client.get(`/admin/users/admins`);
  };

  getAirportUsers = () => {
    return this.client.get(`/admin/users/airport`);
  };

  createAdminUser = (newUser: CreateUserRequest) => {
    return this.client.post(`/admin/users/admins`, newUser);
  };

  createAirportUser = (newUser: CreateUserRequest) => {
    return this.client.post(`/admin/users/airport`, newUser);
  };

  login = (username: string, password: string) => {
    return this.client.post(`/auth/token`, {
      clientId: "1",
      clientSecret: "test1",
      username,
      password,
      grantType: "password",
      refreshToken: "",
    });
  };

  updateAdminUserById = (id: string, updatedUser: UpdateUserRequest) => {
    return this.client.put(`/admin/users/admins/${id}`, updatedUser);
  };

  updateAirportUserById = (id: string, updatedUser: UpdateUserRequest) => {
    return this.client.put(`/admin/users/airport/${id}`, updatedUser);
  };

  deleteAdminUser = (id: string) => {
    return this.client.delete(`/admin/users/admins/${id}`);
  };

  deleteAirportUser = (id: string) => {
    return this.client.delete(`/admin/users/airport/${id}`);
  };

  // ASIC Import
  getASICImports = (limit: number, offset: number) => {
    return this.client.get(`/admin/asic/import-files`, {
      params: {
        limit,
        offset,
      },
    });
  };

  getASICImportById = (id: string) => {
    return this.client.get(`/admin/asic/import-files/${id}`);
  };

  importAsicFile = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return this.client.post(`/admin/file-storage/asic/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  getASICImportFileById = (fileName: string) => {
    return this.client.get(`/admin/file-storage/asic/import/${fileName}`);
  };

  getASICImportReportFileById = (fileName: string) => {
    return this.client.get(`/admin/file-storage/asic/import-report/${fileName}`);
  };

  // File Storage
  getFile = (fileCategory: number, fileName: string) => {
    return this.client.get(`/client/file-storage/${fileCategory}/${fileName}`, {
      responseType: "blob",
    });
  };

  // Person Audits
  getPersonSecuritySubmissions = (
    pageIndex: number,
    pageSize: number,
    sorting: number,
    searchParams: PersonSecuritySubmissionSearchParams
  ) => {
    if (!searchParams.locationName) delete searchParams.locationName;
    if (!searchParams.asicId) delete searchParams.asicId;
    if (!searchParams.firstName) delete searchParams.firstName;
    if (!searchParams.lastName) delete searchParams.lastName;
    if (!searchParams.company) delete searchParams.company;

    return this.client.get(`/admin/person-security-submissions/search`, {
      params: {
        pageIndex,
        pageSize,
        sorting,
        ...searchParams,
      },
    });
  };

  getPersonSecuritySubmissionById = (personSecuritySubmissionId: string) => {
    return this.client.get(`/admin/person-security-submissions/${personSecuritySubmissionId}`);
  };

  // Vehicle Audits
  getVehicleAudits = () => {
    return this.client.get(`/admin/vehicle-audits`);
  };

  getVehicleAuditById = (vehicleAuditId: string) => {
    return this.client.get(`/admin/vehicle-audits/${vehicleAuditId}`);
  };

  // Self Serve Audits
  getSelfServeAudits = (
    pageIndex: number,
    pageSize: number,
    sorting: number,
    searchParams: SelfServeAuditSearchParams
  ) => {
    if (!searchParams.asicId) delete searchParams.asicId;
    if (!searchParams.firstName) delete searchParams.firstName;
    if (!searchParams.lastName) delete searchParams.lastName;
    if (!searchParams.company) delete searchParams.company;
    if (!searchParams.accessLocation) delete searchParams.accessLocation;

    return this.client.get(`/admin/self-serve-submissions`, {
      params: {
        pageIndex,
        pageSize,
        sorting,
        ...searchParams,
      },
    });
  };

  getSelfServeAuditById = (selfServeAuditId: string) => {
    return this.client.get(`/admin/self-serve-submissions/${selfServeAuditId}`);
  };

  updateVisitorPass = (id: any, data: any) => this.client.patch(`/visitor-passes/${id}`, data);

  deleteVisitorPassById = (id: any) => this.client.delete(`/visitor-passes/${id}`);

  approveVisitorPassById = (id: string) => {
    return this.client.post(`/visitor-passes/approve/${id}`);
  };

  rejectVisitorPassById = (id: string, rejectReason: string) => {
    return this.client.post(`/visitor-passes/reject/${id}`, {
      rejectReason: rejectReason,
    });
  };

  cancelVisitorPassById = (id: string, cancelReason: string) => {
    return this.client.post(`/visitor-passes/cancel/${id}`, {
      cancelReason: cancelReason,
    });
  };

  printVisitorPassById = (id: string) => {
    return this.client.post(`/visitor-passes/print/${id}`);
  };

  // AEV Locations
  getAEVLocations = () => {
    return this.client.get(`/admin/locations`);
  };

  getAEVLocationById = (id: string) => {
    return this.client.get(`/admin/locations/${id}`);
  };

  createAEVLocation = (name: string) => {
    return this.client.post(`/admin/locations`, {
      name,
      airportId: 1,
      active: true,
      fixedLocation: true,
      fixedLocationReportingLatitude: null,
      fixedLocationReportingLongitude: null,
    });
  };

  updateAEVLocation = (locationId: string, data: any) => {
    return this.client.put(`/admin/locations/${locationId}`, data);
  };

  deleteAEVLocation = (locationId: string) => {
    return this.client.delete(`/admin/locations/${locationId}`);
  };

  addAEVLocationLane = (locationId: string, laneName: string) => {
    return this.client.post(`/admin/locations/${locationId}/lanes`, {
      laneName,
    });
  };

  deleteAEVLocationLane = (locationId: string, laneId: string) => {
    return this.client.delete(`/admin/locations/${locationId}/lanes/${laneId}`);
  };

  // Self Serve Locations
  getSelfServeLocations = () => {
    return this.client.get(`/admin/self-serve-locations`);
  };

  getSelfServeLocationById = (id: string) => {
    return this.client.get(`/admin/self-serve-locations/${id}`);
  };

  createSelfServeLocation = (name: string) => {
    return this.client.post(`/admin/self-serve-locations`, {
      name,
      airportId: 1,
    });
  };

  updateSelfServeLocation = (locationId: string, data: any) => {
    return this.client.put(`/admin/self-serve-locations/${locationId}`, data);
  };

  deleteSelfServeLocation = (locationId: string) => {
    return this.client.delete(`/admin/self-serve-locations/${locationId}`);
  };

  addSelfServeLocationOperationalRequirement = (
    locationId: string,
    operationalRequirementName: string
  ) => {
    return this.client.post(`/admin/self-serve-locations/${locationId}/operational-requirements`, {
      name: operationalRequirementName,
    });
  };

  updateSelfServeLocationOperationalRequirement = (
    locationId: string,
    operationalRequirementId: string,
    operationalRequirementName: string
  ) => {
    return this.client.put(
      `/admin/self-serve-locations/${locationId}/operational-requirements/${operationalRequirementId}`,
      {
        name: operationalRequirementName,
      }
    );
  };

  deleteSelfServeLocationOperationalRequirement = (
    locationId: string,
    operationalRequirementId: string
  ) => {
    return this.client.delete(
      `/admin/self-serve-locations/${locationId}/operational-requirements/${operationalRequirementId}`
    );
  };

  // ASIC Management
  getAsics = (
    pageIndex: number,
    pageSize: number,
    sorting: number,
    searchParams: AsicSearchParams
  ) => {
    if (!searchParams.firstName) delete searchParams.firstName;
    if (!searchParams.lastName) delete searchParams.lastName;
    if (!searchParams.companyName) delete searchParams.companyName;
    if (!searchParams.asicId) delete searchParams.asicId;

    return this.client.get(`/admin/asic/search`, {
      params: {
        pageIndex,
        pageSize,
        sorting,
        ...searchParams,
      },
    });
  };

  getAsicById = (id: string) => {
    return this.client.get(`/client/asic/${id}`);
  };

  // Other ASICs
  getOtherAsics = (
    pageIndex: number,
    pageSize: number,
    sorting: number,
    searchParams: AsicSearchParams
  ) => {
    if (!searchParams.firstName) delete searchParams.firstName;
    if (!searchParams.lastName) delete searchParams.lastName;
    if (!searchParams.companyName) delete searchParams.companyName;
    if (!searchParams.asicId) delete searchParams.asicId;

    return this.client.get(`/admin/asic/other/search`, {
      params: {
        pageIndex,
        pageSize,
        sorting,
        ...searchParams,
      },
    });
  };

  verifyOtherAsic = (
    otherAsicId: string,
    asicId: string,
    firstName: string,
    lastName: string,
    company: string,
    airportCode: string,
    asicExpiry: string,
    active: boolean
  ) => {
    return this.client.put(`/admin/asic/other/${otherAsicId}/verify`, {
      asicId,
      firstName,
      lastName,
      company,
      airportCode,
      asicExpiry,
      active,
    });
  };

  reviewOtherAsic = (
    otherAsicId: string,
    asicId: string,
    firstName: string,
    lastName: string,
    company: string,
    airportCode: string,
    asicExpiry: string,
    active: boolean
  ) => {
    return this.client.put(`/admin/asic/other/${otherAsicId}/review`, {
      asicId,
      firstName,
      lastName,
      company,
      airportCode,
      asicExpiry,
      active,
    });
  };

  deleteOtherAsic = (otherAsicId: string) => {
    return this.client.delete(`/admin/asic/other/${otherAsicId}`);
  };

  // Airports
  getAirports = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/airports`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  getAirportById = (id: string) => {
    return this.client.get(`/airports/${id}`);
  };

  createAirport = (newAirport: CreateAirportRequest) => {
    return this.client.post(`/airports`, newAirport);
  };

  updateAirportById = (id: string, updatedAirport: UpdateAirportRequest) => {
    return this.client.patch(`airports/${id}`, updatedAirport);
  };

  deleteAirport = (id: string) => {
    return this.client.delete(`airports/${id}`);
  };

  enableAirport = (id: string) => {
    return this.client.post(`airports/enable/${id}`);
  };

  disableAirport = (id: string) => {
    return this.client.post(`airports/disable/${id}`);
  };

  checkIfAirportCanBeDeleted = (id: string) => {
    return this.client.get(`airports/delete/check/${id}`);
  };
}

class ApiV3 {
  token: string | null;
  tokenExpiry: Date | null;
  client: AxiosInstance;
  apiBaseUrl: string;

  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_API_V3_BASE_URL ?? "";
    this.token = localStorage.getItem("v3Token");
    this.tokenExpiry = localStorage.getItem("v3TokenExpiry")
      ? new Date(localStorage.getItem("v3TokenExpiry")!)
      : null;

    const headers: AxiosRequestHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.request.use((request) => {
      if (!this.tokenExpiry || (this.tokenExpiry && new Date() > this.tokenExpiry)) {
        if (!window.location.href.includes("/signin")) {
          window.location.href = "/signout";
        }
      }
      return request;
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response?.status)) {
          if (!window.location.href.includes("/signin")) {
            window.location.href = "/signout";
          }
        }
        return Promise.reject(error);
      }
    );
  }

  updateClient = (newToken: string, expiry: Date | null) => {
    this.token = newToken;
    if (expiry) this.tokenExpiry = expiry;

    // Update API
    const headers: AxiosRequestHeaders = {
      Accept: "application/json",
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.request.use((request) => {
      if (!this.tokenExpiry || (this.tokenExpiry && new Date() > this.tokenExpiry)) {
        if (!window.location.href.includes("/signin")) {
          window.location.href = "/signout";
        }
      }
      return request;
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response?.status)) {
          window.location.href = "/signout";
        }
        return Promise.reject(error);
      }
    );
    return this.client;
  };

  // Required endpoints
  // Airports
  getAirports = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/airports`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  getAirportById = (id: string) => {
    return this.client.get(`/airports/${id}`);
  };

  createAirport = (airportName: string) => {
    return this.client.post(`/airports`, {
      name: airportName,
    });
  };

  updateAirportById = (id: string, airportName: string) => {
    return this.client.patch(`/airports/${id}`, {
      name: airportName,
    });
  };

  enableAirportById = (id: string) => {
    return this.client.post(`/airports/enable/${id}`);
  };

  disableAirportById = (id: string) => {
    return this.client.post(`/airports/disable/${id}`);
  };

  deleteAirportById = (id: string) => {
    return this.client.delete(`/airports/${id}`);
  };

  checkIfAirportCanBeDeleted = (id: string) => {
    return this.client.get(`/airports/delete/check${id}`);
  };

  // API Keys
  getApiKeys = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/security/api-keys`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  getApiKeyById = (id: string) => {
    return this.client.get(`/security/api-keys/${id}`);
  };

  createApiKey = (description: string) => {
    return this.client.post(`/security/api-keys`, {
      description,
    });
  };

  updateApiKeyById = (id: string, description: string) => {
    return this.client.patch(`/security/api-keys/${id}`, {
      description,
    });
  };

  enableApiKeyById = (id: string) => {
    return this.client.post(`/security/api-keys/enable/${id}`);
  };

  disableApiKeyById = (id: string) => {
    return this.client.post(`/security/api-keys/disable/${id}`);
  };

  deleteApiKeyById = (id: string) => {
    return this.client.delete(`/security/api-keys/${id}`);
  };

  // ASIC Management
  getAsics = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    source?: string
  ) => {
    return this.client.get(`/asics`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        source,
      },
    });
  };

  getNewAsics = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    source?: string
  ) => {
    return this.client.get(`/new-asics`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        source,
      },
    });
  };

  getAsicById = (id: string) => {
    return this.client.get(`/asics/${id}`);
  };

  getAsicByAsicId = (asicId: string) => {
    return this.client.get(`/asics/asic-id/${asicId}`);
  };

  verifyManualAsic = (
    id: string,
    asicId: string,
    firstName: string,
    lastName: string,
    company: string,
    airportCode: string,
    expiryDate: string,
    active: boolean
  ) => {
    return this.client.patch(`/asics/verify/${id}`, {
      asicId,
      firstName,
      lastName,
      company,
      airportCode,
      expiryDate,
      active,
    });
  };

  reviewManualAsic = (
    id: string,
    asicId: string,
    firstName: string,
    lastName: string,
    company: string,
    airportCode: string,
    expiryDate: string,
    active: boolean
  ) => {
    return this.client.patch(`/asics/review/${id}`, {
      asicId,
      firstName,
      lastName,
      company,
      airportCode,
      expiryDate,
      active,
    });
  };

  deleteManualAsic = (id: string) => {
    return this.client.delete(`/asics/${id}`);
  };

  // ASIC Import
  getASICImports = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/asic-import`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  getASICImportById = (id: string) => {
    return this.client.get(`/asic-import/${id}`);
  };

  importAsicFile = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return this.client.post(`/asic-import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  downloadAsicImportFile = (asicImportId: string) => {
    return this.client.get(`/asic-import/download/${asicImportId}`);
  };

  downloadAsicImportReportFile = (asicImportId: string) => {
    return this.client.get(`/asic-import/download/report/${asicImportId}`);
  };

  //New ASIC Import
  getNewASICImports = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/new-asic-import`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  getNewASICImportById = (id: string) => {
    return this.client.get(`/new-asic-import/${id}`);
  };

  importNewAsicFile = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return this.client.post(`/new-asic-import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  downloadNewAsicImportFile = (asicImportId: string) => {
    return this.client.get(`/new-asic-import/download/${asicImportId}`);
  };

  downloadNewAsicImportReportFile = (asicImportId: string) => {
    return this.client.get(`/new-asic-import/download/report/${asicImportId}`);
  };

  // Audit Policies
  getAuditPolicies = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/audit-policies/`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  getAuditPolicyById = (id: string) => {
    return this.client.get(`/audit-policies/${id}`);
  };

  createAuditPolicy = (newAuditPolicy: CreateAuditPolicyRequest) => {
    return this.client.post(`/audit-policies`, newAuditPolicy);
  };

  updateAuditPolicyById = (id: string, updatedAuditPolicy: UpdateAuditPolicyRequest) => {
    return this.client.patch(`/audit-policies/${id}`, updatedAuditPolicy);
  };

  checkIfAuditPolicyCanBeDeleted = (id: string) => {
    return this.client.get(`/audit-policies/delete/check/${id}`);
  };

  deleteAuditPolicyById = (id: string) => {
    return this.client.delete(`/audit-policies/${id}`);
  };

  // Locations
  getLocations = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/locations/`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  getLocationById = (id: string) => {
    return this.client.get(`/locations/${id}`);
  };

  createLocation = (newLocation: CreateLocationRequest) => {
    return this.client.post(`/locations`, newLocation);
  };

  updateLocationById = (id: string, updatedLocation: UpdateLocationRequest) => {
    return this.client.patch(`/locations/${id}`, updatedLocation);
  };

  deleteLocationById = (id: string) => {
    return this.client.delete(`/locations/${id}`);
  };

  createLocationLane = (locationId: string, laneName: string) => {
    return this.client.post(`/locations/${locationId}/lanes`, {
      name: laneName,
    });
  };

  updateLocationLaneById = (locationId: string, laneId: string, laneName: string) => {
    return this.client.put(`/locations/${locationId}/lanes/${laneId}`, {
      name: laneName,
    });
  };

  deleteLocationLaneById = (locationId: string, laneId: string) => {
    return this.client.delete(`/locations/${locationId}/lanes/${laneId}`);
  };

  // Location Audit Configurations
  getLocationAuditConfigurationById = (id: string) => {
    return this.client.get(`/location-audit-configurations/${id}`);
  };

  getRandomLocationAuditConfigurationAuditPolicyByLocationId = (locationId: string) => {
    return this.client.get(`/location-audit-configurations/${locationId}/random`);
  };

  createLocationAuditConfiguration = (
    newLocationAuditConfiguration: CreateLocationAuditConfigurationRequest
  ) => {
    return this.client.post(`/location-audit-configurations`, newLocationAuditConfiguration);
  };

  deleteLocationAuditConfigurationById = (id: string) => {
    return this.client.delete(`/location-audit-configurations/${id}`);
  };

  // Notes
  getNotes = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/notes`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  createNote = (label: string) => {
    return this.client.post(`/notes`, {
      label,
    });
  };

  getNoteById = (id: string) => {
    return this.client.get(`/notes/${id}`);
  };

  updateNoteById = (id: string, label: string) => {
    return this.client.patch(`/notes/${id}`, { label });
  };

  deleteNoteById = (id: string) => {
    return this.client.delete(`/notes/${id}`);
  };

  // Operational Neeeds
  getOperationalNeeds = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/operational-needs`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  createOperationalNeeds = (name: string) => {
    return this.client.post(`/operational-needs`, {
      name,
    });
  };

  getOperationalNeedById = (id: string) => {
    return this.client.get(`/operational-needs/${id}`);
  };

  updateOperationalNeedById = (id: string, name: string) => {
    return this.client.patch(`/operational-needs/${id}`, { name });
  };

  deleteOperationalNeedById = (id: string) => {
    return this.client.delete(`/operational-needs/${id}`);
  };

  // Randomized Checks Groups
  getRandomizedChecksGroups = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/randomized-checks-groups`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  createRandomizedChecksGroup = (name: string) => {
    return this.client.post(`/randomized-checks-groups`, {
      name,
    });
  };

  getRandomizedChecksGroupById = (id: string) => {
    return this.client.get(`/randomized-checks-groups/${id}`);
  };

  updateRandomizedChecksGroupById = (id: string, data: any) => {
    return this.client.patch(`/randomized-checks-groups/${id}`, data);
  };

  deleteRandomizedChecksGroupById = (id: string) => {
    return this.client.delete(`/randomized-checks-groups/${id}`);
  };

  // Reports
  getReports = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    type?: string
  ) => {
    return this.client.get(`/reports`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        type,
      },
    });
  };

  getReportById = (id: string) => {
    return this.client.get(`/reports/${id}`);
  };

  downloadReportFile = (reportId: string) => {
    return this.client.get(`/reports/download/${reportId}`);
  };

  deleteReportById = (id: string) => {
    return this.client.delete(`/reports/${id}`);
  };

  // Report Configurations
  getReportConfigurationByReportType = (reportType: string) => {
    return this.client.get(`/reports/configuration/${reportType}`);
  };

  createReportConfigurationForReportType = (
    reportType: string,
    body: CreateReportConfigurationRequest
  ) => {
    return this.client.post(`/reports/configuration/${reportType}`, body);
  };

  updateReportConfigurationForReportType = (
    reportType: string,
    body: UpdateReportConfigurationRequest
  ) => {
    return this.client.patch(`/reports/configuration/${reportType}`, body);
  };

  deleteReportConfigurationForReportType = (reportType: string) => {
    return this.client.delete(`/reports/configuration/${reportType}`);
  };

  // Security Checks
  getSecurityChecks = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    type?: string
  ) => {
    return this.client.get(`/security-checks`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        type,
      },
    });
  };

  getRandomVehicleSecurityChecks = () => {
    return this.client.get(`/security-checks/random/vehicle`);
  };

  getSecurityCheckById = (securityCheckId: string) => {
    return this.client.get(`/security-checks/${securityCheckId}`);
  };

  createSecurityCheck = (data: CreateSecurityCheckRequest) => {
    return this.client.post(`/security-checks`, data);
  };

  updateSecurityCheckById = (id: string, data: UpdateSecurityCheckRequest) => {
    return this.client.patch(`/security-checks/${id}`, data);
  };

  enableSecurityCheckById = (id: string) => {
    return this.client.post(`/security-checks/enable/${id}`);
  };

  disableSecurityCheckById = (id: string) => {
    return this.client.post(`/security-checks/disable/${id}`);
  };

  // Security Tiers
  getSecurityTiers = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/security-tiers`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  getSecurityTierById = (securityTierId: string) => {
    return this.client.get(`/security-tiers/${securityTierId}`);
  };

  createSecurityTier = (data: CreateSecurityTierRequest) => {
    return this.client.post(`/security-tiers`, data);
  };

  updateSecurityTierById = (id: string, data: UpdateSecurityTierRequest) => {
    return this.client.patch(`/security-tiers/${id}`, data);
  };

  deleteSecurityTierById = (id: string) => {
    return this.client.delete(`/security-tiers/${id}`);
  };

  // Self Serve Audits
  getSelfServeAudits = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    fromDate?: string,
    toDate?: string
  ) => {
    return this.client.get(`/self-serve-submissions`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        fromDate,
        toDate,
      },
    });
  };

  getSelfServeAuditById = (selfServeAuditId: string) => {
    return this.client.get(`/self-serve-submissions/${selfServeAuditId}`);
  };

  getSelfServeAuditFileById = (selfServeAuditId: string, filePath: string) => {
    return this.client.get(`/self-serve-submissions/storage/${selfServeAuditId}`, {
      params: {
        filePath,
      },
    });
  };

  getSelfServeSubmissionsCount = (
    searchQuery: string,
    searchField: string,
    fromDate?: string,
    toDate?: string
  ) => {
    return this.client.get(`/self-serve-submissions/count`, {
      params: {
        searchQuery,
        searchField,
        fromDate,
        toDate,
      },
    });
  };

  exportSelfServeSubmissions = async (
    searchQuery: string,
    searchField: string,
    fromDate?: string,
    toDate?: string
  ) => {
    return this.client.get("/self-serve-submissions/export/csv", {
      responseType: "blob",
      timeout: 0,
      params: {
        searchQuery,
        searchField,
        fromDate,
        toDate,
      },
    });
  };

  exportSelfServeSubmissionsToCsv = (selfServeSubmissionIds: string[]) => {
    return this.client.post(`/self-serve-submissions/export/csv`, {
      selfServeSubmissionIds,
    });
  };

  // Self Serve Locations

  getSelfServeLocations = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/self-serve-locations`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  getUserSelfServeLocations = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/user-self-serve-locations`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  createSelfServeLocation = (name: string) => {
    return this.client.post(`/self-serve-locations`, {
      name,
    });
  };

  getSelfServeLocationById = (id: string) => {
    return this.client.get(`/self-serve-locations/${id}`);
  };

  updateSelfServeLocationById = (id: string, data: any) => {
    return this.client.patch(`/self-serve-locations/${id}`, data);
  };

  deleteSelfServeLocationById = (id: string) => {
    return this.client.delete(`/self-serve-locations/${id}`);
  };

  createSelfServeLocationOperationalRequirement = (
    locationId: string,
    operationalRequirementName: string
  ) => {
    return this.client.post(`/self-serve-locations/${locationId}/operational-requirements`, {
      name: operationalRequirementName,
    });
  };

  updateSelfServeLocationOperationalRequirementById = (
    locationId: string,
    operationalRequirementId: string,
    operationalRequirementName: string
  ) => {
    return this.client.put(
      `/self-serve-locations/${locationId}/operational-requirements/${operationalRequirementId}`,
      {
        name: operationalRequirementName,
      }
    );
  };

  deleteSelfServeLocationOperationalRequirementById = (
    locationId: string,
    operationalRequirementId: string
  ) => {
    return this.client.delete(
      `/self-serve-locations/${locationId}/operational-requirements/${operationalRequirementId}`
    );
  };

  // Person Security Submissions
  getPersonSecuritySubmissions = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    fromDate?: string,
    toDate?: string,
    timezone?: string
  ) => {
    return this.client.get(`/person-security-submissions`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        fromDate,
        toDate,
        timezone,
      },
    });
  };

  getPersonSecuritySubmissionsCount = (
    searchQuery: string,
    searchField: string,
    fromDate?: string,
    toDate?: string
  ) => {
    return this.client.get(`/person-security-submissions/count`, {
      params: {
        searchQuery,
        searchField,
        fromDate,
        toDate,
      },
    });
  };

  exportPersonSecuritySubmissions = async (
    searchQuery: string,
    searchField: string,
    fromDate?: string,
    toDate?: string
  ) => {
    return this.client.get("/person-security-submissions/export/csv", {
      responseType: "blob",
      timeout: 0,
      params: {
        searchQuery,
        searchField,
        fromDate,
        toDate,
      },
    });
  };

  getPersonSecuritySubmissionById = (personSecuritySubmissionId: string) => {
    return this.client.get(`/person-security-submissions/${personSecuritySubmissionId}`);
  };

  getPersonSecuritySubmissionFileById = (personSecuritySubmissionId: string, filePath: string) => {
    return this.client.get(`/person-security-submissions/storage/${personSecuritySubmissionId}`, {
      params: {
        filePath,
      },
    });
  };

  exportPersonSecuritySubmissionsToCsv = (personSecuritySubmissionIds: string[]) => {
    return this.client.post(`/person-security-submissions/export/csv`, {
      personSecuritySubmissionIds,
    });
  };

  // Person Security Submission Checkins
  getPersonSecuritySubmissionCheckins = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    fromDate?: string,
    toDate?: string
  ) => {
    return this.client.get(`/person-security-submission-checkins`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        fromDate,
        toDate,
      },
    });
  };

  getPersonSecuritySubmissionCheckinById = (personSecuritySubmissionCheckinId: string) => {
    return this.client.get(
      `/person-security-submission-checkins/${personSecuritySubmissionCheckinId}`
    );
  };

  approvePersonSecuritySubmissionCheckinById = (personSecuritySubmissionCheckinId: string) => {
    return this.client.post(
      `/person-security-submission-checkins/approve/${personSecuritySubmissionCheckinId}`
    );
  };

  declinePersonSecuritySubmissionCheckinById = (personSecuritySubmissionCheckinId: string) => {
    return this.client.post(
      `/person-security-submission-checkins/decline/${personSecuritySubmissionCheckinId}`
    );
  };

  deletePersonSecuritySubmissionCheckinById = (personSecuritySubmissionCheckinId: string) => {
    return this.client.delete(
      `/person-security-submission-checkins/${personSecuritySubmissionCheckinId}`
    );
  };

  getPersonSecuritySubmissionCheckinFileById = (
    personSecuritySubmissionCheckinId: string,
    filePath: string
  ) => {
    return this.client.get(
      `/person-security-submission-checkins/storage/${personSecuritySubmissionCheckinId}`,
      {
        params: {
          filePath,
        },
      }
    );
  };

  // Users
  getUsers = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    role: string
  ) => {
    return this.client.get(`/users`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        role,
      },
    });
  };

  createUser = (newUser: CreateUserRequest, v2Token?: string) => {
    return this.client.post(`/users`, { ...newUser, v2Token });
  };

  loginUser = (username: string, password: string) => {
    // Need to use an unintercepted axios instance to login so that the interceptor
    // doesn't navigate to signout if credentials invalid
    const uninterceptedAxiosInstance = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
    });
    return uninterceptedAxiosInstance.post(`/users/login`, {
      username,
      password,
    });
  };

  getUserById = (id: string) => {
    return this.client.get(`/users/${id}`);
  };

  updateUserById = (id: string, updatedUser: UpdateUserRequest) => {
    return this.client.patch(`/users/${id}`, updatedUser);
  };

  deleteUserById = (id: string) => {
    return this.client.delete(`users/${id}`);
  };
}

export const apiV2 = new ApiV2();
export const apiV3 = new ApiV3();
